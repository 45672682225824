import { AppConfig } from '../app-config'

const developmentConfig: AppConfig = {
  appName: 'dbio-insights',
  baseApiPath: 'http://localhost:21003',
  authenticatedRootUrl: '/home',
  unAuthenticatedRootUrl: '/login',
  authHost: 'http://localhost:21001',
  apmUrl: 'https://staging-apm.databiologics.com',
  tableauProductionBaseUrl: 'https://tableau.databiologics.com/',
  tableauProductionEmbeddedApiUrl:
    'https://tableau.databiologics.com/javascripts/api/tableau.embedding.3.latest.min.js',
  newRelicAccountID: '4313352',
  newRelicTrustKey: '4313352',
  newRelicAgentID: '1103305855',
  newRelicLicenseKey: 'NRJS-c7c9c3e2974022f7dd5',
  newRelicApplicationID: '1103305855',
}

export default developmentConfig
