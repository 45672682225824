import React from 'react'
import { Box, PageTitle } from '@perk-ui/core'

import TableauView from '../../components/TableauView'
import {
  insightsQckinetixEmails,
  insightsSoftwaveEmails,
  insightsStemwaveEmails,
} from '../../config/constants'
import { useUser } from '../../features/auth/AuthContext'

interface DataOverviewProps {}

const DataOverview: React.FC<DataOverviewProps> = () => {
  const currentUser = useUser()

  const isGeneral = currentUser?.insightsUser.type === 'General'

  const isCustomInsightsUser = currentUser
    ? [
        ...insightsSoftwaveEmails,
        ...insightsQckinetixEmails,
        ...insightsStemwaveEmails,
      ].includes(currentUser.email)
    : false
  const isCustomSoftwave = currentUser
    ? insightsSoftwaveEmails.includes(currentUser.email)
    : false
  const isCustomQckinetix = currentUser
    ? insightsQckinetixEmails.includes(currentUser.email)
    : false
  const isCustomStemwave = currentUser
    ? insightsStemwaveEmails.includes(currentUser.email)
    : false
  const workbookUrl = isCustomSoftwave
    ? 'views/SoftWaveInsightAccess/StandardInsightAccessExplorer'
    : isCustomQckinetix
    ? 'views/QCKStandardInsightAccess/StandardInsightAccessExplorer'
    : isCustomStemwave
    ? 'views/StemwaveStandardAccess/ACCESSExplorerStandard'
    : 'views/StudySolutionsv2ProductionServer/StudyOverviewDashboard'

  if (!isGeneral && !isCustomInsightsUser) return <></>
  return (
    <Box p={3}>
      <PageTitle title="Data Overview" />
      <TableauView
        src={workbookUrl}
        type="tableauGeneralToken"
        toolbar="top"
        hideTabs={false}
      />
    </Box>
  )
}

export default DataOverview
