import { useQuery, UseQueryOptions } from 'react-query'

import { useCurrentStudy } from '../../components/StudyProvider'
import request from '../../config/axios'
import queryClient from '../../config/react-query'
import QueryKeys from './QueryKeys'

interface TableauToken {
  type?: 'tableauGeneralToken' | 'tableauStudySponsorToken'
  workbookUrl?: string
}

interface TableauTokenResponse {
  tableauToken: string
}

const useTableauToken = (
  { type, workbookUrl }: TableauToken,
  extraOptions: UseQueryOptions = {},
) => {
  const url = `/insights/tableau_token`
  const currentStudyId = useCurrentStudy()?.id
  let key

  switch (type) {
    case 'tableauGeneralToken':
      key = QueryKeys.tableauGeneralToken()
      break
    case 'tableauStudySponsorToken':
      key = QueryKeys.tableauStudySponsorToken(workbookUrl || '')
      break
    default:
      key = QueryKeys.tableauGeneralToken()
      break
  }

  return useQuery(
    key,
    () =>
      request
        .get<TableauTokenResponse>(url, {
          params: {
            study_id: currentStudyId,
          },
        })
        .then((r) => {
          queryClient.invalidateQueries(QueryKeys.tableauGeneralToken())
          queryClient.invalidateQueries(
            QueryKeys.tableauStudySponsorToken(workbookUrl || ''),
          )
          return r.data.tableauToken
        }),
    {
      ...extraOptions,
      // staleTime: 0,
      // cacheTime: 0,
      // refetchInterval: 0,
    },
  )
}

export default useTableauToken
