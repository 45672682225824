import { useQuery, UseQueryOptions } from 'react-query'

import request from '../../config/axios'
import { InsightsUser, User } from './interfaces'
import QueryKeys from './QueryKeys'

export interface SystemUser extends User {
  insightsUser: InsightsUser
}

interface CurrentUserResponse {
  user: SystemUser
}

const useCurrentUser = (extraOptions: UseQueryOptions<SystemUser> = {}) => {
  const key = QueryKeys.currentUser()
  const url = '/users/current'

  return useQuery(
    key,
    () => request.get<CurrentUserResponse>(url).then((r) => r.data.user),
    {
      // The currentUser's details rarely change, let's avoid refetching
      staleTime: Number.POSITIVE_INFINITY,
      ...extraOptions,
    },
  )
}

export default useCurrentUser
