// export {}
export const LINEAR_GRADIENT_PRIMARY =
  'linear-gradient(30deg, rgba(30,148,207,1) 0%, rgba(142,211,240,1) 100%)'

export const BodyPartColorList = [
  '#76B1BC',
  '#6E91B4',
  '#D87246',
  '#6DA76D',
  '#AD4F59',
  '#8D7CA7',
  '#7C7366',
  '#AA7E96',
  '#808080',
  '#999A6C',
  '#A6C6D1',
  '#D8A68E',
  '#91BA97',
  '#D87E7B',
  '#B58A9D',
  '#B3998C',
  '#CF9FB6',
  '#A9A9A9',
  '#BABD8A',
  '#90B5BE',
  '#76B1BC',
  '#6E91B4',
  '#D87246',
  '#6DA76D',
  '#AD4F59',
  '#8D7CA7',
  '#7C7366',
  '#AA7E96',
  '#808080',
  '#999A6C',
] as const

export const TreatmentsColorList = [
  '#76B1BC',
  '#6E91B4',
  '#D87246',
  '#6DA76D',
  '#AD4F59',
  '#8D7CA7',
  '#7C7366',
  '#AA7E96',
  '#808080',
  '#999A6C',
  '#A6C6D1',
  '#D8A68E',
  '#91BA97',
  '#D87E7B',
  '#B58A9D',
  '#B3998C',
  '#CF9FB6',
  '#A9A9A9',
  '#BABD8A',
  '#90B5BE',
  '#76B1BC',
  '#6E91B4',
  '#D87246',
  '#6DA76D',
  '#AD4F59',
  '#8D7CA7',
  '#7C7366',
  '#AA7E96',
  '#808080',
  '#999A6C',
] as const

export const pathsWithoutHeader = [
  '/login',
  '/forgot-password',
  '/password-reset',
]

export const pathsWithoutSidebar = [
  '/login',
  '/forgot-password',
  '/password-reset',
]

export const staffRoles = ['Provider', 'ClinicStaff']

export const secondaryRoles = [
  'FrontDesk',
  'Scheduling',
  'MedicalAssistant',
  'Phlebotomist',
  'Technician',
  'Scribe',
  'Manager',
  'BusinessOffice',
]

export const providerSpecialties = [
  'Anesthesiology',
  'FamilyMedicine',
  'PainMedicine',
  'PhysicalMedicine&Rehabilitation',
  'PreventiveMedicine',
  'Radiology',
  'Chiropractor',
  'Rheumatology',
  'Podiatry',
  'Naturopath',
  'PhysicianAssistant',
  'NursePractitioner',
]

export const providerTitles = [
  'MD',
  'DO',
  'ND',
  'DPM',
  'DC',
  'NMD',
  'PA',
  'PA-C',
  'NP',
  'RN',
]

export const countryList = [
  {
    label: 'United States',
    value: 'united_states_of_america',
  },
  {
    label: 'Canada',
    value: 'canada',
  },
]

export const canadaTimeZones = [
  'Pacific Time (US & Canada)',
  'Mountain Time (US & Canada)',
  'Central Time (US & Canada)',
  'Eastern Time (US & Canada)',
  'Atlantic Time (Canada)',
  'Newfoundland',
]

export const unitedStatesTimeZones = [
  'Hawaii',
  'Alaska',
  'Pacific Time (US & Canada)',
  'Mountain Time (US & Canada)',
  'Central Time (US & Canada)',
  'Eastern Time (US & Canada)',
]

export const confirmationModalResponse = {
  addClinicSite: {
    success: {
      title: 'Clinic Site Created!',
      body: 'Clinic site has been created successfully.',
    },
    error: {
      title: 'Create Failed!',
      body: 'Sorry - We were unable to create the clinic site.',
    },
    duplicateSiteNumberError: {
      title: 'Create Failed!',
      body: 'Sorry - We were unable to create the clinic site.\nThe clinic site number has already been used.',
    },
  },
  updateClinicSite: {
    success: {
      title: 'Clinic Site Updated!',
      body: 'Clinic site updated successfully.',
    },
    error: {
      title: 'Update Failed!',
      body: 'Sorry - We were unable to update the clinic site.',
    },
    duplicateSiteNumberError: {
      title: 'Update Failed!',
      body: 'Sorry - We were unable to update the clinic site.\nThe clinic site number has already been used.',
    },
  },
  updateClinicSiteAddress: {
    success: {
      title: 'Updated Clinic Site address!',
      body: 'Clinic site address updated successfully.',
    },
    error: {
      title: 'Update Address Failed!',
      body: 'Sorry - We were unable to update the clinic site address.',
    },
  },
  addAccount: {
    success: {
      title: 'Account Created!',
      body: 'Account has been created successfully.',
    },
    error: {
      title: 'Create Failed!',
      body: 'Sorry - We were unable to create the account.',
    },
  },
  updateAccount: {
    success: {
      title: 'Account Updated!',
      body: 'Acccount has been updated successfully.',
    },
    error: {
      title: 'Update Failed!',
      body: 'Sorry - We were unable to update the account.',
    },
  },
  deactivateAccount: {
    success: {
      title: 'Account Deactivated!',
      body: 'The account has been deactivated and the data has been archived in the system.',
    },
    error: {
      title: 'Deactivation Failed!',
      body: 'Sorry - We were unable to deactivate the account.',
    },
  },
  sendAccountVerificationMail: {
    success: {
      title: 'Email Sent!',
      body: 'Verification email have been sent successfully.',
    },
    error: {
      title: 'Email Failed!',
      body: 'Sorry - We were unable to send the verification email.',
    },
  },
}

export const subscriptionPlan = ['essentials', 'performance', 'demo']

export const siteSpecialties = ['Orthopedics', 'Urology/Gynecology']

export const studySponsorWorkbooks = [
  {
    study_lug: 'via-disc-np-registry',
    workbook_url: 'views/VivexStudySolutionsv2/VivexReal-TimeEnrollmentDetail',
  },
  {
    study_lug:
      'platelet-rich-plasma-and-the-effects-of-nsaids-on-pain-and-functional-scores-in-knee-osteoarthritis',
    workbook_url: '',
  },
  {
    study_lug:
      'randomized-controlled-trial-for-use-of-low-intensity-shockwave-for-patients-with-dyspareunia',
    workbook_url:
      'views/SoftWaveDyspareuniaStudy/SoftWaveReal-TimeEnrollmentDetail',
  },
  {
    study_lug: 'softwave-urology-registry',
    workbook_url:
      'views/SoftWaveUrologyStudy/SoftWaveReal-TimeEnrollmentDetail',
  },
  {
    study_lug: 'homologous-use-applications-using-micro-fat-from-bodyjet-eco',
    workbook_url:
      'views/HumanMedBodyJetEcoStudy/CAREstreamReal-TimeEnrollmentDetail',
  },
]

export const insightsSoftwaveEmails = [
  'jwarlick@softwavetrt.com',
  'wspencer@softwavetrt.com',
  'hharper@softwavetrt.com',
  'kjohnson@softwavetrt.com',
]

export const insightsQckinetixEmails = [
  'jgarzone@qckinetix.com',
  'bart@qckinetix.com',
  'scott@qckinetix.com',
  'drollins@qckinetix.com',
  'dhenderson@qckinetix.com',
]

export const insightsStemwaveEmails = ['tom@stemwave.com']
