import './config/env-stage-setup'

import { StrictMode } from 'react'
import * as Sentry from '@sentry/react'
import ReactDOM from 'react-dom'

import App from './App'
import config from './config/app-config'
import reportWebVitals from './reportWebVitals'

Sentry.init({
  dsn: config?.sentryDSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 0.5, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    'https://insights.databiologics.com',
    'https://staging-insights.databiologics.com',
    'https://api.databiologics.com',
    'https://staging-api.databiologics.com',
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  environment: `insights-${process.env.REACT_APP_STAGE}`,
})

ReactDOM.render(
  <StrictMode>
    <App />
  </StrictMode>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
