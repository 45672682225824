import { Dispatch, FC, SetStateAction, useRef, useState } from 'react'
import {
  CSSObject,
  Drawer,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  makeStyles,
  Popover,
  styled,
  Theme,
  Toolbar,
  Tooltip,
} from '@perk-ui/core'
import clsx from 'clsx'
import {
  ChevronDown,
  ChevronsLeft,
  ChevronsRight,
  Folder,
  Layout,
} from 'react-feather'
import { useHistory, useLocation } from 'react-router'

import {
  insightsQckinetixEmails,
  insightsSoftwaveEmails,
  insightsStemwaveEmails,
  pathsWithoutSidebar,
} from '../config/constants'
import { useUser } from '../features/auth/AuthContext'
import { Study } from '../features/query-hooks/interfaces'
import { useStudiesContext } from './StudyProvider'
// import AppLinkButton from './AppLinkButton'

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.background.paper,
  },
  toolbarGap: theme.mixins.toolbar,
  linkContainer: {
    background: theme.palette.background.paper,
    marginTop: theme.spacing(1),
    width: '100%',
    height: '100%',
    maxWidth: 270,
    padding: theme.spacing(0, 0, 2, 0),
    '& .MuiListSubheader-root': {
      fontSize: '20px',
      fontWeight: 500,
      color: theme.palette.common.black,
    },
  },
  marginRight: {
    marginRight: theme.spacing(1),
  },
  link: {
    margin: theme.spacing(0.5, 0),
    padding: theme.spacing(1.7, 1.5),
    borderRadius: 8,
    '&:hover': {
      background: 'rgba(87, 153, 173, 0.04)',
    },
    '& .ListItemText': {
      margin: 0,
    },
    '& .MuiListItemIcon-root': {
      height: 30,
    },
    '& h5': {
      fontSize: 16,
      fontWeight: 400,
    },
    '& svg': {
      margin: 'auto',
    },
    '& span': {
      margin: 'auto',
    },
  },
  fancyButton: {
    '&:hover': {
      // background: theme.palette.primary.light,
    },
  },
  active: {
    // css specificity hack to override `a:link`
    '&&': {
      color: theme.palette.primary.main,
    },
    background: 'rgba(87, 153, 173, 0.04)',
  },
  activeStudy: {
    '&&': {
      color: theme.palette.common.black,
      '& h5': {
        fontWeight: 600,
      },
    },
  },
  subHeader: {
    margin: theme.spacing(0, 1, 2, 1),
    padding: 0,
  },
  subMenu: {},
  subMenuHeader1: {
    margin: theme.spacing(0, 2),
    padding: 0,
    borderBottom: '1px solid ',
    fontSize: '20px',
    fontWeight: 400,
    color: theme.palette.common.black,
  },
  subMenuHeader2: {
    paddingTop: theme.spacing(2),
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
  },
  subMenuLink: {
    margin: theme.spacing(0.5, 0),
    padding: theme.spacing(1, 1.5),
    '&:hover': {
      background: 'rgba(87, 153, 173, 0.04)',
    },
    '& .ListItemText': {
      margin: 0,
      position: 'relative',
      '&::before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        left: 0,
        top: 0,
        borderRadius: '8px',
        width: '4px',
        height: '100%',
      },
    },
    '& svg': {
      margin: 'auto',
    },
    '& h5': {
      fontSize: 16,
      fontWeight: 500,
      paddingLeft: theme.spacing(1),
    },
  },
  disabledLink: {
    color: '#919191 !important',
    cursor: 'not-allowed',
    '& .ListItemText': {
      '&::before': {
        backgroundColor: '#919191',
      },
    },
  },
  disabledTooltip: {
    '& .MuiTooltip-tooltip': {
      maxWidth: '250px',
    },
  },
  drawerHeader: {
    height: '64px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
  },
  drawerContainer: {
    // padding: theme.spacing(0,2),
    '& .MuiDrawer-paper': {
      borderRight: 'none',
      paddingLeft: theme.spacing(2),
    },
  },
  menuLinkIcon: {
    margin: 'auto',
    minWidth: 'unset',
    '& svg': {
      margin: 'auto',
    },
  },
  smallFancyButton: {
    minWidth: 'unset',
    '& .MuiButton-startIcon': {
      margin: 0,
      padding: theme.spacing(0.3),
    },
  },
  // Collapse button Menu
  collapseButtonList: {
    marginTop: theme.spacing(2),
    '& .collapse-button-list-item': {
      '& .ListItemText': {
        marginLeft: theme.spacing(1),
        width: 'auto',
        '& h5': {
          color: '#545454',
          fontSize: '16px',
          fontWeight: '400',
        },
      },
    },
  },
  collapseMenuLinkIcon: {
    margin: 'auto',
    minWidth: 'unset',
    '& svg': {
      margin: 'auto',
    },
  },
  menuExpandButton: {
    margin: 'unset',
    marginLeft: 'auto',
  },
  // Studies Links
  studiesLinkContainer: {
    background: theme.palette.background.paper,
    width: '100%',
    maxWidth: 270,
    '& .MuiListItemIcon-root': {
      height: '50px',
    },
    '& .ListItemText': {
      overflow: 'hidden',
    },
    '& .MuiTypography-body1': {
      color: theme.palette.common.black,
    },
    '& .MuiTypography-body2': {
      color: theme.palette.common.black,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  tooltipClass: {
    '& MuiTooltip-tooltip': {
      borderRadius: '7px',
    },
  },
  noVisible: {
    visibility: 'hidden',
  },
}))

export interface AppSidebarProps {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  drawerWidth: number
}

const AppSidebar: FC<AppSidebarProps> = ({ open, setOpen, drawerWidth }) => {
  const classes = useStyles()
  const { pathname } = useLocation()
  const currentUser = useUser()
  const { currentStudy, setCurrentStudy, studiesQuery } = useStudiesContext()
  const userRole = currentUser?.insightsUser.type
  const isSponsor = userRole === 'StudySponsor'
  const isGeneral = userRole === 'General'
  const isCustomInsightsUser = currentUser
    ? [
        ...insightsSoftwaveEmails,
        ...insightsQckinetixEmails,
        ...insightsStemwaveEmails,
      ].includes(currentUser?.email)
    : false
  const isCustomSoftwave = currentUser
    ? insightsSoftwaveEmails.includes(currentUser?.email)
    : false
  const isCustomQckinetix = currentUser
    ? insightsQckinetixEmails.includes(currentUser?.email)
    : false
  const isCustomStemwave = currentUser
    ? insightsStemwaveEmails.includes(currentUser.email)
    : false
  const isCurrentLocation = (path: string) => pathname.startsWith(path)

  const myRef = useRef<HTMLDivElement>(null)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const openAvailableStudySubmenu = Boolean(anchorEl)
  const availableStudySubmenuId = openAvailableStudySubmenu
    ? `available-study-submenu-popover`
    : undefined

  const handleAvailableStudySubmenuClose = () => {
    setAnchorEl(null)
  }
  const handleOpenAvailableStudySubmenu = () => {
    setAnchorEl(myRef.current)
  }
  const { push } = useHistory()

  const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
  })

  const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(9)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
      width: `calc(${theme.spacing(10)} + 1px)`,
    },
  })

  const MuiDrawer = styled(Drawer, {
    shouldForwardProp: (prop) => prop !== 'open',
  })(({ theme, open }) => ({
    width: drawerWidth,

    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }))

  const handleDraweClick = () => {
    setOpen(!open)
  }

  const handleStudyClick = (study: Study) => {
    setCurrentStudy(study)
    setAnchorEl(null)
  }

  if (pathsWithoutSidebar.some(isCurrentLocation)) return null

  const AvailableStudySubmenu = () => {
    const groupedStudies = studiesQuery.data?.reduce(
      (group: { [key: string]: Study[] }, item) => {
        if (!group[item.studyOrganization.name]) {
          group[item.studyOrganization.name] = []
        }
        group[item.studyOrganization.name].push(item)
        return group
      },
      {},
    )

    return (
      <List
        component="div"
        disablePadding
        className={classes.subMenu}
        subheader={
          <ListSubheader className={classes.subMenuHeader1}>
            Available Studies:
          </ListSubheader>
        }
      >
        {groupedStudies &&
          Object.keys(groupedStudies).map((org) => {
            return (
              <List
                key={org}
                component="div"
                subheader={
                  <ListSubheader className={classes.subMenuHeader2}>
                    {org}
                  </ListSubheader>
                }
              >
                {groupedStudies[org].map((study) => {
                  return (
                    <ListItemButton
                      key={study.id}
                      onClick={() => handleStudyClick(study)}
                      className={clsx(classes.link, {
                        [classes.activeStudy]: currentStudy?.id == study.id,
                      })}
                    >
                      <ListItemText title={study.shortName} />
                    </ListItemButton>
                  )
                })}
              </List>
            )
          })}
      </List>
    )
  }

  return (
    <>
      <div ref={myRef} />
      <MuiDrawer
        variant="permanent"
        open={open}
        className={classes.drawerContainer}
        hideBackdrop
      >
        <Toolbar className={classes.drawerHeader} />
        {/* Collapse Menu Action */}
        <List className={classes.collapseButtonList}>
          <Tooltip
            className={classes.tooltipClass}
            placement="right"
            arrow
            title={open ? '' : 'Expand Sidebar'}
          >
            <ListItemButton
              onClick={handleDraweClick}
              className={clsx(classes.link, 'collapse-button-list-item')}
            >
              <ListItemIcon
                className={clsx(classes.collapseMenuLinkIcon, {
                  [classes.menuExpandButton]: open,
                })}
              >
                {open ? (
                  <ChevronsLeft size={16} />
                ) : (
                  <ChevronsRight size={16} />
                )}
              </ListItemIcon>
              {open && <ListItemText title={'Collapse Sidebar'} />}
            </ListItemButton>
          </Tooltip>
        </List>
        {/* Available studies submenu -- for sponsors user */}
        {isSponsor && !isCustomInsightsUser && (
          <List className={classes.studiesLinkContainer} component="nav">
            <Tooltip
              className={classes.tooltipClass}
              placement="right"
              arrow
              title={
                open ? (
                  ''
                ) : (
                  <>
                    Selected Study <br /> {currentStudy?.shortName}
                  </>
                )
              }
            >
              <ListItemButton
                onClick={handleOpenAvailableStudySubmenu}
                className={clsx(classes.link, 'studies-link-button')}
              >
                <ListItemIcon
                  className={clsx({
                    [classes.menuLinkIcon]: !open,
                  })}
                >
                  <Folder size={20} />
                </ListItemIcon>
                {open && (
                  <ListItemText
                    body="Selected Study"
                    helper={currentStudy?.shortName}
                  />
                )}
                <ChevronDown />
              </ListItemButton>
            </Tooltip>
          </List>
        )}
        <List
          className={classes.linkContainer}
          component="nav"
          subheader={
            <ListSubheader
              className={clsx({
                [classes.noVisible]: !open,
              })}
            >
              {userRole === 'StudySponsor' && !isCustomInsightsUser
                ? 'Study Insights'
                : isCustomSoftwave
                ? 'SoftWave Insight Access'
                : isCustomQckinetix
                ? 'QC Kinetix Insight Access'
                : isCustomStemwave
                ? 'Stemwave Insight Access'
                : 'Insights'}
            </ListSubheader>
          }
        >
          {(isGeneral || isCustomInsightsUser) && (
            <Tooltip
              className={classes.tooltipClass}
              placement="right"
              arrow
              title={open ? '' : 'Data Overview'}
            >
              <ListItemButton
                onClick={() =>
                  isCurrentLocation('/data-overview')
                    ? undefined
                    : push({
                        pathname: `/data-overview`,
                        state: { from: location.pathname },
                      })
                }
                className={clsx(classes.link, {
                  [classes.active]: isCurrentLocation('/data-overview'),
                })}
              >
                <ListItemIcon className={open ? '' : classes.menuLinkIcon}>
                  <Layout
                    className={
                      isCurrentLocation('/data-overview') ? classes.active : ''
                    }
                    size={20}
                  />
                </ListItemIcon>
                {open && <ListItemText title={'Data Overview'} />}
              </ListItemButton>
            </Tooltip>
          )}
          {isSponsor && !isCustomInsightsUser && (
            <Tooltip
              className={classes.tooltipClass}
              placement="right"
              arrow
              title={open ? '' : 'Study Overview'}
            >
              <ListItemButton
                onClick={() =>
                  isCurrentLocation('/study-overview')
                    ? undefined
                    : push({
                        pathname: `/study-overview`,
                        state: { from: location.pathname },
                      })
                }
                className={clsx(classes.link, {
                  [classes.active]: isCurrentLocation('/study-overview'),
                })}
              >
                <ListItemIcon className={open ? '' : classes.menuLinkIcon}>
                  <Layout
                    className={
                      isCurrentLocation('/study-overview') ? classes.active : ''
                    }
                    size={20}
                  />
                </ListItemIcon>
                {open && <ListItemText title={'Study Overview'} />}
              </ListItemButton>
            </Tooltip>
          )}
        </List>
        <Popover
          id={availableStudySubmenuId}
          anchorEl={anchorEl}
          open={openAvailableStudySubmenu}
          onClose={handleAvailableStudySubmenuClose}
          PaperProps={{
            sx: {
              mt: 24,
              ml: 1,
            },
          }}
        >
          <AvailableStudySubmenu />
        </Popover>
      </MuiDrawer>
    </>
  )
}

export default AppSidebar
