import React, { useEffect } from 'react'
import {
  Body1,
  Box,
  Card,
  CardContent,
  ErrorBoundary,
  FallbackProps,
  Grid,
  H3,
  Link,
  makeStyles,
  Toolbar,
} from '@perk-ui/core'

import apm from '../config/analytics'
import AppLink from './AppLink'

export interface AppErrorBoundaryProps {}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '75%',
    margin: 'auto',
  },
  link: {
    cursor: 'pointer',
    '&&': {
      color: theme.palette.primary.main,
    },
  },
  errorDetails: {
    whiteSpace: 'break-spaces',
    lineHeight: 1,
    marginTop: 0,
  },
}))

const AppErrorBoundary: React.FC<AppErrorBoundaryProps> = ({ children }) => (
  <ErrorBoundary FallbackComponent={ErrorPage}>{children}</ErrorBoundary>
)

const ErrorPage: React.FC<FallbackProps> = ({ error, resetErrorBoundary }) => {
  const classes = useStyles()

  useEffect(() => {
    apm.captureError(error)
  }, [apm, error])

  const handleRefreshClick = () => {
    // Full reload of the page
    window.location.reload()
  }

  return (
    <Grid item xs={12}>
      <Toolbar />
      <Box p={3} pl={0} className={classes.root}>
        <Card role="alert">
          <CardContent>
            <H3 paragraph>Something went wrong.</H3>
            <Body1 gutterBottom>
              We&apos;re sorry about that. We&apos;ve alerted our team of this
              issue.
            </Body1>
            <Body1 gutterBottom>
              In the meantime, try{' '}
              <Link
                className={classes.link}
                component="span"
                onClick={handleRefreshClick}
              >
                <b>Refreshing</b>
              </Link>{' '}
              or going back to the{' '}
              <AppLink
                className={classes.link}
                to="/home"
                onClick={resetErrorBoundary}
              >
                <b>Home</b>
              </AppLink>{' '}
              page.
            </Body1>
            <br />
            <Body1>Details for our team</Body1>
            <pre className={classes.errorDetails}>{error.message}</pre>
          </CardContent>
        </Card>
      </Box>
    </Grid>
  )
}

export default AppErrorBoundary
