import deepmerge from 'deepmerge'

import { AppConfig } from '../app-config'
import developmentConfig from './development'

const productionConfig = deepmerge<AppConfig>(developmentConfig, {
  baseApiPath: 'https://api.databiologics.com/',
  authHost: 'https://auth.databiologics.com/',
  apmUrl: 'https://apm.databiologics.com/',
  tableauProductionBaseUrl: 'https://tableau.databiologics.com/',
  tableauProductionEmbeddedApiUrl:
    'https://tableau.databiologics.com/javascripts/api/tableau.embedding.3.latest.min.js',
  newRelicAccountID: '4313352',
  newRelicTrustKey: '4313352',
  newRelicAgentID: '1103305446',
  newRelicLicenseKey: 'NRJS-c7c9c3e2974022f7dd5',
  newRelicApplicationID: '1103305446',
  sentryDSN:
    'https://b5864863156f0070c60fb0367c91c0a7@o4506389729181696.ingest.us.sentry.io/4507368921694208',
})

export default productionConfig
