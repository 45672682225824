import { FC } from 'react'
import { Box, makeStyles } from '@perk-ui/core'
import parse from 'html-react-parser'

import config from '../config/app-config'
// import { useUser } from '../features/auth/AuthContext'
import useTableauToken from '../features/query-hooks/useTableauToken'
import useExternalScripts from '../hooks/useExternalScripts'
import useWindowSize from '../hooks/useWindowSize'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    // padding: theme.spacing(3),
    overflow: 'auto',
  },
}))

export interface TableauViewProps {
  src: string
  type?: 'tableauGeneralToken' | 'tableauStudySponsorToken'
  workbookUrl?: string
  tableauId?: string
  toolbar?: 'top' | 'bottom' | 'hidden'
  hideTabs?: boolean
}

const TableauView: FC<TableauViewProps> = ({
  src,
  type,
  workbookUrl,
  tableauId,
  toolbar,
  hideTabs,
  ...rest
}) => {
  const classes = useStyles()
  const device = useWindowSize()
  const { data: tableauToken } = useTableauToken({
    type,
    workbookUrl,
  })
  const width = '100%'
  const height = 'calc(100vh - 120px)'
  const tableauEmbeddedApiUrl = config.tableauProductionEmbeddedApiUrl
  const tableauBaseUrl = config.tableauProductionBaseUrl

  useExternalScripts(tableauEmbeddedApiUrl || '', 'module')

  if (!tableauToken) return <></>
  // switch (device) {
  //   case 'desktop':
  //     width = '100%'
  //     height = 'calc(100vh - 120px)'
  //     break
  //   case 'tablet':
  //     width = '100%'
  //     height = 'calc(100vh - 120px)'
  //     break
  //   case 'phone':
  //     width = '100%'
  //     height = 'calc(100vh - 120px)'
  //     break
  //   default:
  //     width = '100%'
  //     height = 'calc(100vh - 120px)'
  //     break
  // }
  // Parameters
  // '<viz-parameter name="host_url" value="https://tableau-staging.databiologics.com/"></viz-parameter>' +
  // '<viz-parameter name="embed_code_version" value="3"></viz-parameter>' +
  // '<viz-parameter name="site_root" value=""></viz-parameter>' +
  // '<viz-parameter name="name" value="StagingEssentials_v3Datasource&#47;PracticeEssentials"></viz-parameter>' +
  // '<viz-parameter name="tabs" value="yes"></viz-parameter>' +
  // '<viz-parameter name="toolbar" value="yes"></viz-parameter>' +
  // '<viz-parameter name="showAppBanner" value="false"></viz-parameter>' +
  // '<viz-parameter name="showShareOptions" value="false"></viz-parameter>' +
  const tableauEmbededView =
    '<tableau-viz ' +
    'src="' +
    tableauBaseUrl +
    src +
    '&:showShareOptions=false' +
    '" ' +
    'token="' +
    tableauToken +
    '" ' +
    'id="' +
    (tableauId ? tableauId : 'tableauViz') +
    '" ' +
    'toolbar="' +
    (toolbar ? toolbar : 'hidden') +
    '" ' +
    'device="' +
    device +
    '" ' +
    (hideTabs ? 'hide-tabs ' : '') +
    'width="' +
    width +
    '" ' +
    'height="' +
    height +
    '" ' +
    '>' +
    '</tableau-viz>'

  return (
    <Box className={classes.root} {...rest}>
      {parse(tableauEmbededView)}
    </Box>
  )
}

export default TableauView
