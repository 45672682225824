import { useQuery, UseQueryOptions } from 'react-query'

import request from '../../config/axios'
import { Study } from './interfaces'
import QueryKeys from './QueryKeys'

export interface SiteLocation {
  id: string
  siteId: string
  isPrimary: boolean
  street: string
  unitApartmentSuite: string
  city: string
  stateProvince: string
  zipCode: string
  phone: string
}

interface SstudiesAvailableResponse {
  studies: Study[]
}

const useStudiesAvailable = (extraOptions: UseQueryOptions<Study[]> = {}) => {
  const key = QueryKeys.studiesAvailable()
  const url = `/insights/available_studies`

  return useQuery(
    key,
    () =>
      request.get<SstudiesAvailableResponse>(url).then((r) => r.data.studies),
    extraOptions,
  )
}

export default useStudiesAvailable
