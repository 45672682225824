import React, { useState } from 'react'
import {
  Alert,
  Button,
  ensureArray,
  Link,
  makeStyles,
  PageTitle,
  PasswordField,
  PasswordResetContainer,
} from '@perk-ui/core'
import { KeratinError } from 'keratin-authn/dist/types'
import { useRouteMatch } from 'react-router'

import AppLink from '../../../components/AppLink'
import AuthBackground from '../../../components/AuthBackground'
import apm from '../../../config/analytics'
import { useAuth } from '../../../features/auth/AuthContext'

export interface PasswordResetLocationParams {
  token: string
}

export interface PasswordResetProps {}

const useStyles = makeStyles((theme) => ({
  login: {
    '& .MuiFormControl-root': {
      paddingBottom: theme.spacing(2),
    },
  },
  password: {
    marginTop: theme.spacing(3),
  },
  loginButton: {
    marginTop: theme.spacing(3),
  },
  alert: {
    color: theme.palette.success.dark,
  },
}))

interface PasswordResetErrors {
  passwordConfirm?: string
  password?: string
  insecurePassword?: string
  tokenIssue?: string

  // eslint-disable-next-line
  errorObjects?: any
}

const COUNTDOWN_SECONDS = 3

const PasswordReset: React.FC<PasswordResetProps> = () => {
  const classes = useStyles()
  const { resetPassword, initialize } = useAuth()
  const [password, setPassword] = useState('')
  const [passwordConfirm, setPasswordConfirm] = useState('')
  const [errors, setErrors] = useState<PasswordResetErrors>(
    {} as PasswordResetErrors,
  )
  const [ticker, setTicker] = useState<number>(COUNTDOWN_SECONDS)
  const [didReset, setDidReset] = useState(false)
  const [loading, setLoading] = useState(false)

  const match = useRouteMatch<PasswordResetLocationParams>()
  const token = match.params.token

  const startTicking = () => {
    const tId = window.setInterval(() => {
      setTicker((tick) => {
        if (tick <= 1) {
          window.clearInterval(tId)
        }

        return tick - 1
      })
    }, 1000)
  }

  const handleResetPassword = () => {
    if (!/(?=.{8,})/.test(password)) {
      return setErrors({
        password: 'Password must be at least 8 characters',
      })
    }
    if (!/(?=.*[0-9])/.test(password)) {
      return setErrors({
        password: 'Password must have a number',
      })
    }
    if (!/(?=.*[^A-Za-z0-9])/.test(password)) {
      return setErrors({
        password: 'Password must have a symbol',
      })
    }

    if (password !== passwordConfirm) {
      return setErrors({
        passwordConfirm: 'Passwords must match',
      })
    }

    setLoading(true)
    return resetPassword({ password, token })
      .then((newToken) => {
        if (!newToken) {
          // Reset failed for an unknown reason. Bail
          const error = new Error('Failed to reset password. Token invalid.')
          setErrors({
            errorObjects: [error],
          })
          throw error
        }

        setDidReset(true)
        setErrors({})
        startTicking()

        setTimeout(() => {
          initialize()
        }, COUNTDOWN_SECONDS * 1000)
      })
      .catch((error) => {
        apm.captureError(error)
        const keratinErrors = error as KeratinError[]

        const hasInsecurePassword = keratinErrors.some(
          (e) => e.field === 'password' && e.message === 'INSECURE',
        )
        if (hasInsecurePassword) {
          return setErrors({
            insecurePassword:
              'That is a common password. Please try something more unique.',
          })
        }

        const hasTokenIssue = keratinErrors.some((e) => e.field === 'token')
        if (hasTokenIssue) {
          return setErrors({
            tokenIssue:
              'It looks like your password reset link has expired. Please click below to get a new one.',
          })
        }

        setErrors({
          errorObjects: keratinErrors,
        })
      })
      .finally(() => setLoading(false))
  }

  return (
    <AuthBackground className={classes.login}>
      <PageTitle title="Password Reset" />
      <PasswordResetContainer
        MessagesComponent={
          <>
            {didReset && (
              <Alert className={classes.alert} severity="success" fade grow>
                Password successfully reset!
                <br />
                Redirecting you in {ticker} seconds.
              </Alert>
            )}
            {errors.insecurePassword && (
              <Alert severity="error" fade grow>
                {errors.insecurePassword}
              </Alert>
            )}
            {errors.tokenIssue && (
              <Alert severity="error" fade grow>
                {errors.tokenIssue}
                <br />
                <br />
                <AppLink to="/forgot-password">Request a new link</AppLink>
              </Alert>
            )}
            {errors.errorObjects && (
              <Alert severity="error" fade grow>
                Password reset failed. Please send the below information or a
                screenshot to{' '}
                <Link
                  href="mailto:support@databiologics.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  support@databiologics.com
                </Link>
                <br />
                <br />
                {ensureArray(errors.errorObjects).map((eo) => {
                  return eo instanceof Error
                    ? eo.toString()
                    : JSON.stringify(eo, undefined, 4)
                })}
              </Alert>
            )}
          </>
        }
        PasswordComponent={
          <PasswordField
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            helperText={
              (password && errors.password) ||
              '8+ characters, a number, and a symbol please.'
            }
            error={!!errors.password}
          />
        }
        ConfirmPasswordComponent={
          <PasswordField
            label="Confirm Password"
            value={passwordConfirm}
            onChange={(e) => setPasswordConfirm(e.target.value)}
            helperText={passwordConfirm && errors.passwordConfirm}
            error={!!errors.passwordConfirm}
          />
        }
        ButtonComponent={
          <Button loading={loading} onClick={handleResetPassword}>
            Reset password
          </Button>
        }
      />
    </AuthBackground>
  )
}

export default PasswordReset
