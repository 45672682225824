import React from 'react'
import { Body1, Box, BoxProps, H3, makeStyles } from '@perk-ui/core'

import { ReactComponent as BlankState } from '../in-bundle-assets/BlankStateA.svg'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexFlow: 'column',
    textAlign: 'center',
    justifyContent: 'center',
    padding: theme.spacing(3),
    height: '100%',
    '& svg': {
      margin: '0 auto',
    },
  },
  title: {
    color: '#767676',
    fontSize: '24px',
    fontWeight: '700',
    paddingTop: theme.spacing(3),
  },
  body: {
    color: '#767676',
    fontSize: '16px',
    fontWeight: '400',
    paddingTop: theme.spacing(2),
    maxWidth: '500px',
    margin: '0 auto',
  },
}))

export interface ResultProps extends BoxProps {
  title: string
  body: string
  type: string
}

const Result: React.FC<ResultProps> = ({ title, body, type }) => {
  const classes = useStyles()
  return (
    <Box className={classes.root}>
      {type === 'error' && (
        <BlankState
          height={150}
          width={250}
          title="Welcome to DataBiologics' survey app"
        />
      )}
      <H3 className={classes.title}>{title}</H3>
      <Body1 className={classes.body}>{body}</Body1>
    </Box>
  )
}

export default Result
