/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  login,
  logout,
  requestPasswordReset,
  resetPassword,
  restoreSession,
  session,
  setHost,
  setLocalStorageStore,
} from 'keratin-authn'
import { KeratinError } from 'keratin-authn/dist/types'

import apm from '../../config/analytics'
import config from '../../config/app-config'

setHost(config.authHost)
setLocalStorageStore('insightsToken')

// TODO: Refactor from a class to a simple set of functions
export class AuthService {
  public initAuthenticatedSession = async () => {
    try {
      await restoreSession()
    } catch (err: any) {
      if (err === 'No session.') {
        // There is not an existing token to restore.
        // This is a normal failure case.
      } else {
        // Something unusual happened. Re-throw error to alert caller
        apm.captureError(err)
      }

      throw err
    }

    return session()
  }

  public signIn = async (
    username: string,
    password: string,
  ): Promise<string | undefined> => {
    try {
      await login({
        username,
        password,
      })
      return session()
    } catch (err: any) {
      const error = err as KeratinError[]
      // TODO: properly handle errors,
      // One error case: err === [{ message: 'connection failed' }]
      apm.captureError(err)
      throw error
    }
  }

  public resetPassword = async (args: Parameters<typeof resetPassword>[0]) => {
    await resetPassword(args)
    return session()
  }

  public signOut = logout
  public requestPasswordReset = requestPasswordReset
}
