const QueryKeys = {
  currentUser: () => ['currentUser'],
  tableauGeneralToken: () => ['tableauGeneralToken'],
  tableauStudySponsorToken: (workbookUrl: string) => [
    'tableauStudySponsorToken',
    workbookUrl,
  ],
  studiesAvailable: () => ['studiesAvailable'],
}

export default QueryKeys
