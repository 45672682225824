import { createPerkTheme } from '@perk-ui/core'

const template = createPerkTheme({
  palette: {
    primary: {
      // main: 'rgb(46, 128, 153)',
      main: '#56AAEB',
      light: '#E3F9FF',
      dark: '#1D2B57',
    },
    secondary: {
      main: 'rgb(255, 225, 199)',
    },
    success: {
      main: 'rgb(0, 168, 0)',
    },
    error: {
      main: 'rgb(179, 0, 0)',
    },
  },
  typography: {
    fontFamily: ['Roboto', 'sans-serif'].join(','),
  },
})

export const templateTheme = createPerkTheme({
  palette: template.palette,
  typography: {
    fontFamily: template.typography.fontFamily,
  },
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          borderRadius: '7px',
        },
      },
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        body: {
          // Prevents the horizontal scrollbar from showing when transitioning Routes
          overflowX: 'hidden',
        },
      },
    },
    MuiDialog: {
      paperFullWidth: {
        width: 'calc(100% - 48px)',
      },
    },
  },
})
