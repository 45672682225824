import React from 'react'
import { Box, Toolbar } from '@perk-ui/core'
import { Redirect, Route, Switch, useLocation } from 'react-router'

import PrivateRoute from '../components/PrivateRoute'
import config from '../config/app-config'
import { pathsWithoutSidebar } from '../config/constants'
import { useAuth } from '../features/auth/AuthContext'
import ForgotPassword from './Authentication/ForgotPassword/ForgotPassword'
import Login from './Authentication/Login/Login'
import PasswordReset from './Authentication/PasswordReset/PasswordReset'
import DataOverview from './GeneralUsers/DataOverview'
import Home from './Home/Home'
import StudyOverview from './StudySponsorUsers/StudyOverview'

const Routes: React.FC = React.memo(() => {
  const { isAuthenticated } = useAuth()
  const { pathname } = useLocation()
  const isCurrentLocation = (path: string) => pathname.startsWith(path)

  let showToolbar = true
  if (pathsWithoutSidebar.some(isCurrentLocation)) showToolbar = false
  return (
    <Box
      component="main"
      sx={{ flexGrow: 1, px: 3, backgroundColor: '#FAFAFB' }}
    >
      {showToolbar && <Toolbar style={{ height: '64px' }} />}
      <Switch>
        {/**
         * Authentication
         */}
        <Route path={config.unAuthenticatedRootUrl} component={Login} />
        <Route path="/forgot-password" component={ForgotPassword} />
        <Route path="/password-reset/:token" component={PasswordReset} />

        {/**
         * Home
         */}
        <PrivateRoute path="/home" component={Home} />

        {/**
         * General Users
         */}
        <PrivateRoute path="/data-overview" component={DataOverview} />

        {/**
         * Study Sponsor Users
         */}
        <PrivateRoute path="/study-overview" component={StudyOverview} />

        {/**
         * Catch-all
         */}
        <Route
          path="*"
          render={() => (
            <Redirect
              to={
                isAuthenticated
                  ? config.authenticatedRootUrl
                  : config.unAuthenticatedRootUrl
              }
            />
          )}
        />
      </Switch>
    </Box>
  )
})

export default Routes
