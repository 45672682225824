import React from 'react'
import { ApmRoute } from '@elastic/apm-rum-react'
import { Redirect, RouteProps } from 'react-router-dom'

import config from '../config/app-config'
import { useAuth } from '../features/auth/AuthContext'

export type PrivateRouteProps = RouteProps

/**
 * A Route which requires the user to be authenticated before mounting the Route's children.
 *
 * If the user is not authenticated, redirects back to `/login` with info about what the user
 * was trying to access.
 */
const PrivateRoute: React.FC<PrivateRouteProps> = (props) => {
  const { isAuthenticated, isLoading } = useAuth()

  if (isLoading) return null

  return !isAuthenticated ? (
    <Redirect to={config.unAuthenticatedRootUrl} />
  ) : (
    <ApmRoute {...props} />
  )
  // window.alert("You don't have permission to this site")
}

export default PrivateRoute
