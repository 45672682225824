import React from 'react'
import {
  BackgroundContainer,
  BackgroundContainerProps,
  makeStyles,
} from '@perk-ui/core'
import clsx from 'clsx'

import BrandLogo from '../assets/BrandLogo.png'

const useStyles = makeStyles(() => ({
  root: {
    alignItems: 'center',
  },
}))

export interface AuthBackgroundProps
  extends Partial<BackgroundContainerProps> {}

const AuthBackground: React.FC<AuthBackgroundProps> = ({
  className,
  ...rest
}) => {
  const classes = useStyles()

  return (
    <BackgroundContainer
      className={clsx(classes.root, className)}
      logo={BrandLogo}
      logoWidth={213}
      {...rest}
    />
  )
}

export default AuthBackground
