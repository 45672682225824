import { createContext, FC, useContext, useEffect } from 'react'
import { useStorage } from '@perk-ui/core'

// import { useHistory } from 'react-router'
import { useIsAuthenticated } from '../features/auth/AuthContext'
import { Study } from '../features/query-hooks/interfaces'
import useStudiesAvailable from '../features/query-hooks/useStudiesAvailable'

interface StudiesState {
  currentStudy: Study | null // TODO update the currentStudy type to be not-nullable
  setCurrentStudy: (study: Study) => void
  studiesQuery: ReturnType<typeof useStudiesAvailable>
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const StudiesContext = createContext<StudiesState>(undefined as any)
export const useStudiesContext = () => useContext(StudiesContext)
export const useCurrentStudy = () =>
  useContext(StudiesContext).currentStudy as Study

const StudiesProvider: FC = ({ children }) => {
  const isAuthenticated = useIsAuthenticated()
  // const { push } = useHistory()
  const studiesQuery = useStudiesAvailable({ enabled: isAuthenticated })
  const [currentStudy, setCurrentStudy] = useStorage<Study | null>(
    'localStorage',
    'currentStudy',
    null,
  )

  // If the currentStudy is no longer in our list of available Studies, unset the currentStudy
  useEffect(() => {
    if (currentStudy && studiesQuery.data?.length) {
      const currentStudyIsAvailable = studiesQuery.data.some(
        (study) => study.id === currentStudy.id,
      )
      if (!currentStudyIsAvailable) {
        setCurrentStudy(studiesQuery.data[0])
      }
      // if (currentStudy !== studiesQuery.data[0]) {
      //   setCurrentStudy(studiesQuery.data[0])
      // }
    } else {
      if (studiesQuery.data) setCurrentStudy(studiesQuery?.data[0])
    }
  }, [currentStudy, studiesQuery.data])

  if (studiesQuery.data && !studiesQuery.data.length) {
    // throw new Error('Logged-in User does not belong to any clinic sites.')
    // Need to check if we should redirect to onboarding
  }

  const contextValue: StudiesState = {
    currentStudy,
    setCurrentStudy,
    studiesQuery,
  }

  return (
    <StudiesContext.Provider value={contextValue}>
      {children}
    </StudiesContext.Provider>
  )
}

export default StudiesProvider
