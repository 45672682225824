import { capitalize } from '@perk-ui/core'

interface FullName {
  firstName: string
  lastName: string
}

export const buildName = ({ firstName, lastName }: FullName) =>
  `${firstName} ${lastName}`

export const humanizeBodySite = (str: string) => {
  if (str === 'foot-toes') return 'Foot / Toe'
  if (str === 'wrist-hand') return 'Wrist / Hand'

  return str.toLowerCase().split('-').map(capitalize).join(' ')
}

// camelCase to space-separated Capital Case
export const keyToLabel = (key: string) => {
  // Body parts
  if (key === 'wristHand') return 'Wrist / Hand'
  if (key === 'footToes') return 'Foot / Toes'
  if (key === 'lumbosacralPelvis') return 'Lumbosacral / Pelvis'
  if (key === 'ankleAchilles') return 'Achilles'
  if (key === 'brain') return 'Autonomic Nervous System'
  // Treatments
  if (key === 'a2m') return 'A2M'
  if (key === 'ppp') return 'PPP'
  if (key === 'prp') return 'PRP'
  if (key === 'emtt') return 'EMTT'
  if (key === 'ssBmc') return 'SuperShot BMC'
  if (key === 'ssPrp') return 'SuperShot PRP'
  if (key === 'aps') return 'APS'

  return Array.from(key)
    .flatMap((char) => (char.match(/[A-Z]/) ? [' ', char] : [char]))
    .join('')
    .split(' ')
    .map(capitalize)
    .join(' ')
}

export const formatPhoneNumber = (phoneNumberString: string) => {
  let cleaned = ('' + phoneNumberString).replace(/\D/g, '')
  if (cleaned.length === 11) cleaned = cleaned.substring(1)
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3]
  }
  return phoneNumberString
}
