import React from 'react'
import { Box, PageTitle } from '@perk-ui/core'

import Result from '../../components/Result'
import { useStudiesContext } from '../../components/StudyProvider'
import TableauView from '../../components/TableauView'
import { studySponsorWorkbooks } from '../../config/constants'
import { useUser } from '../../features/auth/AuthContext'

interface StudyOverviewProps {}

const StudyOverview: React.FC<StudyOverviewProps> = () => {
  const { currentStudy } = useStudiesContext()
  const currentUser = useUser()
  const currentStudyWorkbook =
    studySponsorWorkbooks.find(
      (study) => study.study_lug === currentStudy?.slug,
    )?.workbook_url || ''
  const isSponsor = currentUser?.insightsUser.type === 'StudySponsor'

  // const isCustomInsightsUser = [...insightsSoftwaveEmails,...insightsQckinetixEmails].includes(currentUser.email)
  if (!isSponsor) return <></>
  return (
    <Box p={3} style={{ height: 'calc(100% - 64px)' }}>
      <PageTitle title="Study Overview" />
      {currentStudyWorkbook ? (
        <TableauView
          src={currentStudyWorkbook}
          type="tableauStudySponsorToken"
          workbookUrl={currentStudyWorkbook}
          toolbar="top"
          hideTabs={false}
        />
      ) : (
        <Result
          title="Hmm... nothing to show yet!"
          body="Once the connection to our databases is active, you’ll be able to access it through this same screen."
          type="error"
        />
      )}
    </Box>
  )
}

export default StudyOverview
