import './env-stage-setup'

import { getPlatforms } from '@perk-ui/core'

import { templateTheme } from './theme'

// attach device/runtime platform classes to the <html> element
getPlatforms()

if (!__PROD__) {
  // For debugging and convenience purposes, we make the theme and
  // date-fns available on the `window`
  // eslint-disable-next-line
  ;(window as any).theme = templateTheme
  import('date-fns').then((dateFns) => {
    // eslint-disable-next-line
    ;(window as any).dateFns = dateFns
  })
}
