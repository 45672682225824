import { FC } from 'react'
import { HeaderBar, makeStyles } from '@perk-ui/core'
import { useLocation } from 'react-router-dom'

import { pathsWithoutHeader } from '../config/constants'
import config from '../config/environments/development'
import { useAuth } from '../features/auth/AuthContext'
import AppLink from './AppLink'
import ProfileDropdown from './ProfileDropdown'

export interface AppHeaderProps {}

const useStyles = makeStyles((theme) => ({
  gap: {
    paddingRight: theme.spacing(3),
  },
  header: {
    zIndex: 1201,
    boxShadow: 'none',
  },
}))

const AppHeader: FC<AppHeaderProps> = () => {
  const classes = useStyles()
  const { isAuthenticated } = useAuth()
  const { pathname } = useLocation()
  const isCurrentLocation = (path: string) => pathname.startsWith(path)
  const showHeader = !pathsWithoutHeader.some(isCurrentLocation)

  return showHeader ? (
    <HeaderBar
      className={classes.header}
      left={
        <AppLink
          to={
            isAuthenticated
              ? config.authenticatedRootUrl
              : config.unAuthenticatedRootUrl
          }
        >
          <img
            src={`${process.env.PUBLIC_URL}/assets/DataBiologics_Logo.png`}
            height="30"
          />
        </AppLink>
      }
      right={isAuthenticated && <ProfileDropdown />}
      position="fixed"
    />
  ) : null
}

export default AppHeader
