/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import { Box, H1, makeStyles, PageTitle } from '@perk-ui/core'
import { Redirect } from 'react-router-dom'

import {
  insightsQckinetixEmails,
  insightsSoftwaveEmails,
  insightsStemwaveEmails,
} from '../../config/constants'
import { useUser } from '../../features/auth/AuthContext'

interface HomeProps {}

const useStyles = makeStyles((theme) => ({
  dashboardTitle: {
    marginBottom: theme.spacing(3),
  },
}))

const Home: React.FC<HomeProps> = () => {
  const classes = useStyles()
  const currentUser = useUser()
  const isGeneral = currentUser?.insightsUser.type === 'General'
  const isSponsor = currentUser?.insightsUser.type === 'StudySponsor'
  const isCustomInsightsUser = currentUser
    ? [
        ...insightsSoftwaveEmails,
        ...insightsQckinetixEmails,
        ...insightsStemwaveEmails,
      ].includes(currentUser.email)
    : false

  if (isGeneral || isCustomInsightsUser) {
    return <Redirect to={'/data-overview'} />
  } else if (isSponsor) {
    return <Redirect to={'/study-overview'} />
  }

  return (
    <Box p={3}>
      <PageTitle title="Home" />
      <H1 className={classes.dashboardTitle}>{'Insights'}</H1>
    </Box>
  )
}

export default Home
