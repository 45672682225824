import React, { useState } from 'react'
import {
  Button,
  Icon,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from '@perk-ui/core'
import { LogOut, User } from 'react-feather'

import { useAuth, useUser } from '../features/auth/AuthContext'

export interface ProfileDropdownProps {}

const ProfileDropdown: React.FC<ProfileDropdownProps> = () => {
  const { signOut } = useAuth()
  const user = useUser()
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null)
  const isOpen = Boolean(anchorEl)
  const menuId = 'user-profile-dropdown-id'

  const handleOpen = (evt: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(evt.currentTarget)
  }

  const handleSelect = () => {
    setAnchorEl(null)
  }

  const handleLogout = () => {
    signOut().then(handleSelect)
  }

  return (
    <>
      <Button
        variant="text"
        size="medium"
        aria-haspopup="true"
        onClick={handleOpen}
        aria-controls={menuId}
        // startIcon={
        //   <Icon>
        //     <ChevronDown />
        //   </Icon>
        // }
        endIcon={
          <Icon>
            <User />
          </Icon>
        }
      >
        {user?.insightsUser.firstName}
      </Button>
      <Menu
        id={menuId}
        anchorEl={anchorEl}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        open={isOpen}
        onClose={() => handleSelect()}
        keepMounted
      >
        <MenuItem onClick={() => handleLogout()}>
          <ListItemIcon>
            <LogOut fontSize="small" />
          </ListItemIcon>
          <ListItemText body="Sign Out" />
        </MenuItem>
      </Menu>
    </>
  )
}

export default ProfileDropdown
