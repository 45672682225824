import './config/setupGlobals'

import { FC, useEffect, useState } from 'react'
import {
  AppBarProps as MuiAppBarProps,
  Box,
  generateNewRelicSnippet,
  Helmet,
  PerkThemeProvider,
  styled,
} from '@perk-ui/core'
import { QueryClientProvider } from 'react-query'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import { QueryParamProvider } from 'use-query-params'

import AppErrorBoundary from './components/AppErrorBoundary'
import AppHeader from './components/AppHeader'
import AppSidebar from './components/AppSidebar'
import StudiesProvider from './components/StudyProvider'
import config from './config/app-config'
import queryClient from './config/react-query'
import { templateTheme } from './config/theme'
import AuthProvider from './features/auth/AuthContext'
import Routes from './pages/Routes'

const {
  newRelicAccountID,
  newRelicTrustKey,
  newRelicAgentID,
  newRelicLicenseKey,
  newRelicApplicationID,
} = config

const drawerWidth = 290

interface AppBarProps extends MuiAppBarProps {
  open?: boolean
}

const AppBar = styled(AppHeader, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}))

const App: FC = () => {
  // const classes = useStyles()
  const [open, setOpen] = useState(true)

  //new relic
  useEffect(() => {
    if (!__PROD__ && !__STAGING__) return

    const newRelicConfig = {
      accountID: newRelicAccountID,
      trustKey: newRelicTrustKey,
      agentID: newRelicAgentID,
      licenseKey: newRelicLicenseKey,
      applicationID: newRelicApplicationID,
    }

    const snippet = generateNewRelicSnippet(newRelicConfig)

    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.textContent = snippet

    document.head.appendChild(script)
  }, [])

  return (
    <PerkThemeProvider theme={templateTheme}>
      <QueryClientProvider client={queryClient}>
        <Router>
          <QueryParamProvider ReactRouterRoute={Route}>
            <Helmet
              titleTemplate="%s | DataBiologics"
              defaultTitle="DataBiologics"
            />
            <AuthProvider>
              <StudiesProvider>
                <AppErrorBoundary>
                  <Box sx={{ display: 'flex', height: '100vh' }}>
                    <AppBar open={open} />
                    <AppSidebar
                      open={open}
                      setOpen={setOpen}
                      drawerWidth={drawerWidth}
                    />
                    <Routes />
                  </Box>
                </AppErrorBoundary>
              </StudiesProvider>
            </AuthProvider>
          </QueryParamProvider>
        </Router>
      </QueryClientProvider>
    </PerkThemeProvider>
  )
}

export default App
